import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { Log } from '../models/log';
import { LogsService } from './logs.service';
import { MensajesService } from './mensajes.service';
import { PedidoAdr } from '../models/pedido';

@Injectable({
  providedIn: 'root'
})
export class GestionAdrService {
  constructor(
    private http: HttpClient,
    public afs: AngularFirestore,
    private logService: LogsService,
    private msjs: MensajesService
  ) {
  }

  /**
   * =============================================
   * API Express JS
   * (Api encargada de conectar ADR y Tracker APP)
   * =============================================
   */

  /**
   * @Servicio :Transportistas
   * @param idTransportista
   * @returns Ruta[]
   */
  cargarRutasTransportista(idTransportista: string) {
    return this.http.get(`${environment.ipApiBackend}/transportistas/obtener-rutas?idTransportista=${idTransportista}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );

  }

  /**
   * @Servicio :Pedidos
   * @param nroRuta
   * @returns PedidoAdr[]
   */
  listarPedidosRuta(nroRuta: string, idTransportista: string) {
    return this.http.get<PedidoAdr[]>(`${environment.ipApiBackend}/rutas/listar-pedidos?nroRuta=${nroRuta}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  /**
   * @Servicio :Pedidos
   * @param idTransportista
   * @returns PedidoAdr
   */
  obtenerPedidoRuta(nroRuta: string, nroPedido: string) {
    return this.http.get(`${environment.ipApiBackend}/pedidos/obtener-pedido-ruta?nroRuta=${nroRuta}&nroPedido=${nroPedido}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  obtenerRuta(nroRuta: string, idTransportista: string) {
    return this.http.get(`${environment.ipApiBackend}/rutas/obtener-ruta?nroRuta=${nroRuta}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  /**
   * @Servicio :Pedidos
   * @param nroRuta, nroPedido, idEstado (Opcional)
   * Modifica el estado de revisión del pedido
   */
  revisarPedidoRuta(nroRuta: string, nroPedido: string) {
    return this.http.get(`${environment.ipApiBackend}/pedidos/revisar-pedido?nroRuta=${nroRuta}&nroPedido=${nroPedido}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  /**
   * @Servicio :Rutas
   * @param nroRuta, idEstado (Opcional)
   * Modifica el estado de revisión de la ruta
   */
  revisarRuta(nroRuta: string) {
    return this.http.get(`${environment.ipApiBackend}/rutas/revisar-ruta?nroRuta=${nroRuta}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  /**
   * @Servicio :Rutas
   * @param nroRuta, idEstado (Opcional)
   * Modifica estado de ruta en ADR
   */
  cambiarEstadoRuta(nroRuta: string) {
    return this.http.get(`${environment.ipApiBackend}/rutas/cambiar-estado?nroRuta=${nroRuta}`)
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  /**
   * @Servicio :Pedidos
   * @param nroRuta, entityId
   * Actualiza estado del pedido en ADR y Magento
   */
  entregarPedido(entityId: string, nroPedido: string) {
    return this.http.post(`${environment.ipApiBackend}/pedidos/entregar-pedido?entityId=${entityId}&nroPedido=${nroPedido}`, {})
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  /**
   * @Servicio :Rutas
   * @param nroRuta
   * Finaliza el estado de la ruta (Una vez que todos los pedidos han sido entregados)
   */
  finalizarRuta(nroRuta: string) {
    return this.http.post(`${environment.ipApiBackend}/rutas/finalizar-ruta-transportista?nroRuta=${nroRuta}`, {})
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }

  /**
   * Manejo de errores
   */
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('Ha ocurrido un error en el backend:', error.message);
    } else {
      console.error(
        `Backend retorno error ${error.status}, Descripción: `, error.name);
    }

    const log: Log = new Log();
    log.id = this.afs.createId();
    log.fecha = new Date().toLocaleString('es-CL');
    log.status = error.status;
    log.mensaje = error.message;
    log.mensaje = `Error al ejecutar consulta de API`;

    this.logService.agregarLog(log).then(
      () => {
        this.msjs.mensajeError(
          'Lo sentimos',
          `Ha ocurrido un error al cambiar el estado de la ruta.`
        );
      },
      (err) => {
        console.warn(`No hemos podido registrar el error: ${err}`);
      }
    );
    return throwError(
      'En el servicio backend.');
  }
}
