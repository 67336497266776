export class RutaAdr {
    nroRuta: string;
    nroPedidos: number;
    estadoRevision: string;
    estadoRuta: string;
    fechaRuta: string;
}

export class Ruta {
    nroRuta: string;
    estado: string;
    estadoRevision: string;
    fechaRuta: string;
    idTransportista: string;
    nombreTransportista: string;
    inicioEntrega: string;
    finEntrega: string;
    pedidos: string[];
}
