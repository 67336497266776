import { Imagen } from './imagen';

// Registro de entrega de pedidos
export class Entrega {
    nroRuta: string;
    nroPedido: string;
    idTransportista: string;
    nombreTransportista: string;
    inicioEntrega: string;
    finEntrega: string;
    actualizado: string;
    estadoEntrega: string;
    motivoDevolucion?: string;
    imagenes?: Imagen[];
    nombreReceptor: string;
    rutReceptor?: string;
    ubicacion: Ubicacion;
}

export class Comentario {
    idComentario: string;
    nroRuta: string;
    nroPedido: string;
    fechaComentario: string;
    estadoEntrega: string;
    comentario: string;
    ubicacion: Ubicacion;
}
export interface Ubicacion {
    latitud: number;
    longitud: number;
}
