import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Ruta } from '../models/ruta';
import { MensajesService } from './mensajes.service';
import { LogsService } from './logs.service';
import { Log } from '../models/log';

@Injectable({
  providedIn: 'root'
})
export class RutasService {
  private url = 'rutas';
  constructor(
    public afs: AngularFirestore,
    private msjs: MensajesService,
    private logService: LogsService
  ) { }

  agregarRuta(ruta: Ruta) {
    const ref = this.afs.collection<Ruta>(this.url);
    return ref.doc(ruta.nroRuta).set(Object.assign({}, ruta))
      .catch(error => {
        const log: Log = new Log();
        log.id = this.afs.createId();
        log.fecha = new Date().toLocaleString('es-CL');
        log.status = error.status;
        log.mensaje = error.message;
        log.descripcion = `Error al registrar ruta ${ruta.nroRuta} en cloud.`;
        log.transportista = ruta.idTransportista;
        log.nroRuta = ruta.nroRuta;

        this.logService.agregarLog(log).then(
          () => {
            this.msjs.mensajeError(
              'Lo sentimos',
              `Ha ocurrido un error al registrar la ruta en Cloud.`
            );
          },
          (err) => {
            console.warn(`No hemos podido registrar el error: ${err}`);
          }
        );
        return;
      });
  }

  actualizarRuta(ruta: Ruta) {
    const ref = this.afs.collection<Ruta>(this.url);
    return ref.doc(ruta.nroRuta).update(Object.assign({}, ruta))
      .catch(error => {
        const log: Log = new Log();
        log.id = this.afs.createId();
        log.fecha = new Date().toLocaleString('es-CL');
        log.status = error.status;
        log.mensaje = error.message;
        log.descripcion = `Error al actualizar ruta ${ruta.nroRuta} en cloud.`;
        log.transportista = ruta.idTransportista;
        log.nroRuta = ruta.nroRuta;

        this.logService.agregarLog(log).then(
          () => {
            this.msjs.mensajeError(
              'Lo sentimos',
              `Ha ocurrido un error al actualizar la ruta en Cloud.`
            );
          },
          (err) => {
            console.warn(`No hemos podido registrar el error: ${err}`);
          }
        );
        return;
      });
  }

  obtenerRuta(nroRuta: string) {
    const ref = this.afs.collection<Ruta>(this.url);
    return ref.doc(nroRuta).valueChanges();
  }

  listarRutasActivasTransportista(idTransportista: string) {
    return this.afs.collection<Ruta>(
      // eslint-disable-next-line max-len
      this.url, ref => ref.where('idTransportista', '==', idTransportista).where('estado', '==', 'Ruta entregada a Transportista')).valueChanges();
  }

  listarRutasFinalizadasTransportista(idTransportista: string) {
    return this.afs.collection<Ruta>(
      // eslint-disable-next-line max-len
      this.url, ref => ref.where('idTransportista', '==', idTransportista).where('estado', '==', 'Finalizada')).valueChanges();
  }

  listarRutasFinalizadas() {
    return this.afs.collection<Ruta>(
      this.url, ref => ref.where('estado', '==', 'Finalizada')).valueChanges();

  }
}
