import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Usuario } from '../models/usuario';
import { Log } from '../models/log';
import { LogsService } from './logs.service';
import { MensajesService } from './mensajes.service';


@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private url = 'usuarios';
  constructor(
    public afs: AngularFirestore,
    private logService: LogsService,
    private msjs: MensajesService
  ) { }

  actualizarUsuario(usuario: Usuario, idTransportista: string) {
    const ref = this.afs.collection<Usuario>(this.url);
    return ref.doc(usuario.idFirebase).update(Object.assign({}, usuario)).catch(error => {
      const log: Log = new Log();
      log.id = this.afs.createId();
      log.fecha = new Date().toLocaleString('es-CL');
      log.status = error.status;
      log.mensaje = error.message;
      log.descripcion = `Error al actualizar el usuario del transportista ${idTransportista}.`;
      log.transportista = idTransportista;

      this.logService.agregarLog(log).then(
        () => {
          this.msjs.mensajeError(
            'Lo sentimos',
            `Ha ocurrido un error al actualizar el usuario del Transportista.`
          );
        },
        (err) => {
          console.warn(`No hemos podido registrar el error: ${err}`);
        }
      );
      return;
    });
  }

  obtenerUsuario(idUsuario: string) {
    const ref = this.afs.collection<Usuario>(this.url);
    return ref.doc(idUsuario).valueChanges();
  }

  async actualizarAvatarUsuario(idUsuario: string, avatarUrl: string) {
    const ref = this.afs.collection(this.url).doc(idUsuario);
    try {
      await ref.set({ avatar: avatarUrl }, { merge: true });
      console.log(`Se actualizo avatar ${avatarUrl}`);
      console.log('La avatar del usuario ha sido actualizado');
    } catch (err) {
      console.error(`Ha ocurrido un error, no hemos podido actualizar el avatar \n ${err}`);
    }
  }

}
