import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Entrega } from '../models/entrega';
import { Imagen } from '../models/imagen';
import { Log } from '../models/log';
import { MensajesService } from './mensajes.service';
import { LogsService } from './logs.service';
import { catchError, retry } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntregasService {
  private url = 'entregas';
  constructor(
    public afs: AngularFirestore,
    private msjs: MensajesService,
    private logService: LogsService
  ) { }

  agregarEntrega(entrega: Entrega) {
    const ref = this.afs.collection<Entrega>(this.url);
    return ref.doc<Entrega>(entrega.nroPedido).set(Object.assign({}, entrega))
      .catch(error => {
        const log: Log = new Log();
        log.id = this.afs.createId();
        log.fecha = new Date().toLocaleString('es-CL');
        log.status = error.status;
        log.mensaje = error.message;
        log.descripcion = `Error al registrar la entrega ${entrega.nroPedido} en cloud.`;
        log.transportista = entrega.idTransportista;
        log.nroPedido = entrega.nroPedido;
        log.nroRuta = entrega.nroRuta;

        this.logService.agregarLog(log).then(
          () => {
            this.msjs.mensajeError(
              'Lo sentimos',
              `Ha ocurrido un error al registrar la entrega en Cloud.`
            );
          },
          (err) => {
            console.warn(`No hemos podido registrar el error: ${err}`);
          }
        );
        return;
      });
  }

  actualizarEntrega(entrega: Entrega) {
    const ref = this.afs.collection<Entrega>(this.url);
    return ref.doc(entrega.nroPedido).update(Object.assign({}, entrega))
      .catch(error => {
        const log: Log = new Log();
        log.id = this.afs.createId();
        log.fecha = new Date().toLocaleString('es-CL');
        log.status = error.status;
        log.mensaje = error.message;
        log.descripcion = `Error al actualizar la entrega ${entrega.nroPedido} en cloud.`;
        log.transportista = entrega.idTransportista;
        log.nroPedido = entrega.nroPedido;
        log.nroRuta = entrega.nroRuta;

        this.logService.agregarLog(log).then(
          () => {
            this.msjs.mensajeError(
              'Lo sentimos',
              `Ha ocurrido un error al actualizar la entrega en Cloud.`
            );
          },
          (err) => {
            console.warn(`No hemos podido registrar el error: ${err}`);
          }
        );
        return;
      });
  }

  obtenerEntrega(nroPedido: string) {
    const ref = this.afs.collection<Entrega>(this.url);
    return ref.doc(nroPedido).valueChanges()
      .pipe(
        retry(2),
        catchError(this.handleError)
      );
  }


  listarEntregas(nroRuta: string, nroPedido: string) {
    return this.afs.collection<Entrega>(
      this.url, ref => ref.where('nroRuta', '==', nroRuta).where('nroPedido', '==', nroPedido)).valueChanges();
  }

  listarEntregasFinalizadas() {
    return this.afs.collection<Entrega>(
      this.url, ref => ref.where('estado', '==', 'finalizada')).valueChanges();

  }

  listarEntregasPorEstadoYTransportista(estadoEntrega: string, nroRuta: string) {
    return this.afs.collection<Entrega>(
      this.url, ref =>
      ref.where('estadoEntrega', '==', estadoEntrega)
      &&
      ref.where('nroRuta', '==', nroRuta)).valueChanges();
  }

  async actualizarImagenesdeEntrega(nroPedido: string, imagenes: Imagen[]) {
    const ref = this.afs.collection(this.url).doc(nroPedido);
    const refEntrega = await ref.set({ imagenes }, { merge: true });
  }

  /**
   * ==============================================
   * Manejo de errores
   * ==============================================
   */
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('Ha ocurrido un error en el backend:', error.message);
    } else {
      console.error(
        `Backend retorno error ${error.status}, Descripción: `, error.name);
    }
    return throwError(
      'En el servicio backend.');
  }
}
