import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Comentario } from '../models/entrega';

@Injectable({
  providedIn: 'root'
})
export class ComentariosService {
  private url = 'comentarios';
  constructor(public afs: AngularFirestore) { }

  agregarComentario(comentario: Comentario) {
    const ref = this.afs.collection<Comentario>(this.url);
    return ref.doc(comentario.idComentario).set(Object.assign({}, comentario));
  }

  listarComentariosEntrega(nroRuta: string, nroPedido: string) {
    return this.afs.collection<Comentario>(
      this.url, ref => ref.where('nroRuta', '==', nroRuta).where('nroPedido', '==', nroPedido)).valueChanges();
  }

}
