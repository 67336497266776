import { Imagen } from './imagen';

export class PedidoAdr {
    nroRuta: string;
    nroPedido: string;
    idTransportista: string;
    estadoRevision: string;
    direccion: string;
    comuna: string;
}

// Registro de entrega de pedidos
export class Pedido {
    nroRuta: string;
    nroPedido: string;
    entityId: string;
    fechaCompra: string;
    idTransportista: string;
    nombreTransportista: string;
    diasDesdeCompra?: number;
    horasDesdeCompra?: number;
    direccion: string;
    comuna: string;
    estadoRevision: string;
    estadoEntrega: string;
    nombreCliente: string;
    rutCliente?: string;
    emailCliente?: string;
    telefonoCliente: string;
}






